import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'textos'
})
export class TextosPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): string {
    console.log(value);
    if (value)
      value = value.replace("xogar elixe contra", "quere roubarlle unha carta específica a").replace("xogar descartes", "coller unha carta da pila de descartes").replace("xogar attack", "saltar turno atacando").replace("xoga attack", "salta turno atacando: 2 turnos pra quen lle segue!").replace("xoga future", "bota unha ollada ás tres próximas cartas do mazo").replace("xoga shuffle", "baralla as cartas do mazo").replace("xogar skip", "saltar turno").replace("xoga skip", "salta turno").replace("xogar shuffle", "barallar as cartas do mazo").replace("xogar future", "ver as próximas tres cartas do mazo").replace("xogar rouba contra", "roubarlle unha carta ao chou a").replace("xogando favor contra", "pedíndolle unha carta a").replace("xoga favor contra", "pídelle unha carta a").replace("xogar favor contra", "pedirlle unha carta a")
    switch (value) {
      case "shuffle":
        return "Mezclar cartas"

      case "elixe":
        return "Roubar unha carta específica"

      case "favor":
        return "Pedir unha carta"

      case "skip":
        return "Saltar turno"

      case "rouba":
        return "Roubar carta sen mirar"

      case "future":
        return "Ver o futuro"

      case "descartes":
        return "Roubar dos descartes"
      
      case "attack":
        return ("Atacar ao seguinte turno")

      default:
        return value;
    }
  }

}
