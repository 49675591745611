import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css']
})
export class ModalComponent implements OnInit {

  @Input("dataFromPartida") data: any;

  @Output() modalRes = new EventEmitter<any>();

  posicionExploding = -1;
  constructor() { }

  posExploding(pos) {
    if (pos < 0)
      pos = this.data.cartasMazo.length - 1;
    this.modalRes.emit({ "posExploding": pos });
  }

  colleCarta(id) {
    this.modalRes.emit({ "colleCarta": id });
  }

  entregaCarta(id) {
    this.modalRes.emit({ "entregaCarta": id });
  }

  cartasMezcladas() {
    this.modalRes.emit({ "cartasMezcladas": true,  atacante: this.data.atacante, cartas: this.data.cartas })
    this.data.cartasMezcladas = true;
  }
  pideCarta(tipo) {
    console.log(tipo)
    this.modalRes.emit({ "pideCarta": tipo })
  }


  selectVictima(orde) {
    console.log(orde)
    this.modalRes.emit({ "selectVictima": orde })
  }

  sendToParent(data) {
    this.modalRes.emit(data);
  }

  ngOnInit(): void {
  }

  getCardsWidth(cards) {
    if (typeof cards != "number")
      cards = cards.length
    if (cards > 25)
      return "100px"
    else if (cards > 15) //15
      return "150px"
    else return "200px"
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.data.cartas, event.previousIndex, event.currentIndex);
    console.log(this.data.cartas)
  }

  dropExploding(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.data.cartasMazo, event.previousIndex, event.currentIndex);
    console.log(event.currentIndex)
    this.posicionExploding = event.currentIndex;
    console.log(this.posicionExploding)
    //console.log(this.data.cartas)
  }
}
