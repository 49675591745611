<div id="notification" class="alert w-50 alert-{{data.tipo}} " role="alert" [ngClass]="{'show': data.show }">
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="data.show=false">
        <span aria-hidden="true">&times;</span>
    </button>

    <div class="row" *ngIf="data">
        <svg *ngIf="!data.gif" class="col-2" style="margin-top: auto; margin-bottom: auto;" xmlns="http://www.w3.org/2000/svg" width="50"
            height="50" fill="currentColor" class="bi bi-exclamation-triangle-fill flex-shrink-0 me-2"
            viewBox="0 0 16 16" role="img" aria-label="Warning:">
            <path
                d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
        </svg>
        <img *ngIf="data.gif" style="margin-top: auto; margin-bottom: auto;" width="80px" [src]="'assets/images/' + data.gif">
        <div class="col-9">
            <h2 style="margin-top: auto; margin-bottom: auto; text-align: center"> {{data.texto | textos}}</h2>
        </div>
    </div>
    <hr>
    <pre class="mb-0 muted">{{data.info | textos}}.</pre>
<div *ngIf="data.espera" class="progress">
    <div class="progress-bar progress-bar-striped  bg-{{data.tipo}}" role="progressbar"
        [ngStyle]="{'width': data.progreso * 100/data.espera + '%'}" aria-valuenow="10" aria-valuemin="0"
        aria-valuemax="100"></div>
</div>
</div>