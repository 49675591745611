import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSliderModule } from '@angular/material/slider';
import { HomeComponent } from './home/home.component';
import { PartidaComponent } from './partida/partida.component';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { FormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ModalComponent } from './partida/modal/modal.component';
import { NotificationComponent } from './partida/notification/notification.component';
import { TextosPipe } from './partida/textos.pipe';

//TODO: Cambiar dinamicamente segun dev/deploy

//const config: SocketIoConfig = { url: 'https://149.202.191.123:3000', options: {} };

const config: SocketIoConfig = { url: 'https://sockets.deiki.eu/', options: {} };


//const config: SocketIoConfig = { url: 'http://localhost:3000', options: {} };

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    PartidaComponent,
    ModalComponent,
    NotificationComponent,
    TextosPipe
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatSliderModule,
    SocketIoModule.forRoot(config),
    DragDropModule

  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
