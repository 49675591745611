import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { Observable } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class WebSocketService {

  constructor(private socket: Socket) {
    this.socket.on("connect_error", (err) => {
      console.log(`connect_error due to ${err}`);
    });
   }

  listen(eventName: string) {
    console.log("listen");
    return new Observable((Suscriber) => {
      this.socket.on(eventName, (data) => {
        console.log("Ola!", this.socket)
        Suscriber.next(data)
      })
    })
  }

  emit(eventName: string, data: any) {
    console.log("emit", eventName, data)
    this.socket.emit(eventName, (data));
  }

}
