import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { WebSocketService } from '../services/web-socket.service';
import { CdkDragEnd } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-partida',
  templateUrl: './partida.component.html',
  styleUrls: ['./partida.component.css']
})
export class PartidaComponent implements OnInit {
  nope = null;
  // TEST cartaEnXogo = null;
  timeout: number;
  ataqueEnCurso = false;
  victima = null;
  cartasFuturas: any;
  xogadaMultiple = [];
  xogadaActual = {};
  rivalXogando: any;

  nomeXogador(orde) {
    let rival = this.rivals.find(rival => rival.orde == orde)
    if (rival)
      return rival.nome;
    return "??"
  }
  modalRes(res) {
    if (typeof res.posExploding == "number") {
      //TODO: QUITA O PLAYDEFUSE, QUE O XOGUE CON UNHA ANIMACIÓN
      this.eu.ocultaExploding = false;
      this.modalData = null
      console.log(res.posExploding); //se enviaría para meter no mazo
      this.eu.cartas = this.eu.cartas.filter(c => c.type != "exploding")
      this.webService.emit("explodingToMazo", { partida: this.idPartida, kitten: this.eu.orde, posicion: res.posExploding });
    }

    if (typeof res.selectVictima == "number") {
      this.victima = res.selectVictima;
      this.modalData = null;
      setTimeout(() => {
        this.iniciaXogada();
      }, 200);
    }

    if (res.cartasMezcladas) {
      this.continuaXogada(res.cartas)
    }

    if (res.pideCarta) {
      console.log("pide carta: " + res.pideCarta);
      this.modalData = null;
      this.cartasEnMesa(this.xogadaMultiple, this.victima, res.pideCarta)
    }

    if (typeof res.colleCarta == "number") {
      this.continuaXogada(res.colleCarta)

    }
    if (res.entregaCarta) {
      this.descarta([res.entregaCarta])
      this.continuaXogada(res.entregaCarta)

    }
    console.log(res);
  }
  //TODO: Sobra dragPosition??
  /*
    dragPosition = { x: 0, y: 0 };
  
    changePosition() {
      this.dragPosition = { x: this.dragPosition.x + 50, y: this.dragPosition.y + 50 };
    } */

  mousedown(event) {
    console.log(event.target)
    event.target.style.zIndex = parseInt(event.target.style.zIndex) + 5000;
    console.log(event.target.style.zIndex)
  }

  mouseup(event) {
    if (event.target.style.zIndex > 4999)
      event.target.style.zIndex = parseInt(event.target.style.zIndex) - 5000
    console.log(event.target.style.zIndex)
  }


  private openModal(data) {
    this.modalData = data;
    setTimeout(() => {
      document.getElementById("openModalButton").click();
    }, 200);
  }

  private podoXogar(carta: any) {
    if (this.turno < 0 || (this.xogadaActual["ataque"]))
      return false;

    let cartasXogadas = this.xogadaMultiple.map(c => c.type)
    let numeroCartas = this.xogadaMultiple.length;

    let nope = (numeroCartas == 1 && carta.type == 'nope')
    let turno = this.turno == this.eu.orde

    //en tempo de espera dunha xogada só se poden xogar nopes
    if (this.xogadaActual["espera"] > 0) {
      //TODO: Se mostras aviso borra o anterior!
      //if (!nope)
      //  this.showNotification("Xogada en curso", "Só podes xogar un 'nope'", "warning")
      return nope;
    }

    if (nope) { // só pode xogarse cando hai xogada en curso
      this.showNotification("Carta incorrecta", "Só se xoga nope para impedir a xogada doutra persoa!", "warning", 3)
      return false;
    }

    //en caso contrario non podo xogar si non é o meu turno
    if (!turno) {
      this.showNotification("Non é o teu turno!", "", "warning", 3)
      return false;
    }

    console.log(cartasXogadas)

    if (numeroCartas > 1) {
      if (![2, 3, 5].includes(numeroCartas)) {
        this.showNotification("Cartas mal seleccionadas", "Xogadas múltiples permitidas: \n2 cartas iguais\n3 cartas iguais\n5 cartas diferentes", "warning")
        return false;
      }
      if (numeroCartas == 2 && cartasXogadas[0] != cartasXogadas[1]) {
        this.showNotification("Cartas mal seleccionadas!",
          "Para xogar 2 cartas teñen que ser iguais", "warning")
        return false
      }
      if (numeroCartas == 3 &&
        (cartasXogadas[0] != cartasXogadas[1] || cartasXogadas[0] != cartasXogadas[2])
      ) {
        this.showNotification("Cartas mal seleccionadas!",
          "Para xogar 3 cartas teñen que iguais", "warning")
        return false
      }
      if (numeroCartas == 5) {
        // teñen que ser as cinco distintas

        if ([...cartasXogadas].length < 5) { //cartas únicas
          this.showNotification("Cartas mal seleccionadas!",
            "Para xogar 5 cartas teñen que ser 5 cartas distintas", "warning")

          return false
        }
      }
      //TODO: TEÑEN QUE PODER VERSE? (POR SE QUERES ROUBAR ALGO QUE NON SEXA UN... PERO DE MOMENT)
    }

    if (numeroCartas < 2 && (carta.type.includes("kitten") || carta.type == "defuse")) {
      this.showNotification("Non podes xogar esta carta sola", "Xoga unha carta distinta ou esta combinada con outras..", "danger", 2)
      return false;
    }

    return true


  }

  dragMazoEnd(event: CdkDragEnd<string[]>) {
    console.log(event)
    let y = event.source.element.nativeElement.getBoundingClientRect().y

    event.source.element.nativeElement.classList.toggle('cardFlip');
    // event.source.element.nativeElement.style.transform = 'none'
    if (y > document.getElementById("tabs").getBoundingClientRect().y + 250) {
      console.log("Carta collida!")
      this.robaCarta();
      //TODO: BLOQUEAR PANTALLA OU ACCIÓNS MIENTRAS ESPERA RESPOSTA?
    }
    else {
      event.source._dragRef.reset();
    }
  }

  idPartida: string;

  topMazo = 0;
  rightMazo = -200;
  cartasMazo = 0;
  cartasDescartes = [];
  testY = 0;
  testR = -26;
  rivals = [];
  players = [];
  turno = -2;
  eu = {
    hash: '',
    nome: '',
    orde: -1,
    cartas: [],
    ocultaExploding: false
  }
  modalData = null;
  notificationData = {};
  normas: {};
  subscription: Subscription;
  constructor(
    private activated: ActivatedRoute,
    private router: Router,
    private webService: WebSocketService
  ) { }

  ngOnDestroy(): void {
    if (this.subscription)
      this.subscription.unsubscribe();
  }

  mezclaCarta(timeout, k) {
    setTimeout(() => {
      let carta = document.getElementById("mazo" + k);
      let endPos = Math.floor(Math.random() * this.cartasMazo)
      if (endPos == this.cartasMazo - 1)
        carta.style.zIndex = "1000";
      carta.style.transform = "translate(" + (endPos < k ? 220 : -20) + "px, -6px)";

      let x = endPos - k
      document.getElementById("mazo" + endPos).style.zIndex = "0"
      carta.style.zIndex = endPos.toString();
      setTimeout(() => {
        setTimeout(() => {
          carta.style.transform = "translate(0px, 0px)";

        }, 20);

      }, 200);
    }, timeout * 100);
  }
  mezcla() {
    this.topMazo = 1;
    this.rightMazo = -199;
    let k = 0;
    for (let index = 0; index < this.cartasMazo; index++) {
      let carta = document.getElementById("mazo" + index);
      console.log(carta.style.transition)
      carta.style.zIndex = index.toString()
      carta.style.transition = "transform 0.8s, margin-top 1.8s, margin-right 1.5s";
    }
    let interval = setInterval(() => {
      this.mezclaCarta(1, this.cartasMazo - 1)
      this.mezclaCarta(Math.floor(Math.random() * 20), k)
      this.mezclaCarta(Math.floor(Math.random() * 20), Math.floor(Math.random() * this.cartasMazo))
      k++
      if (k == this.cartasMazo) {

        clearInterval(interval)
        setTimeout(() => {
          this.topMazo = 0;
          this.rightMazo = -200;
          for (let index = 0; index < this.cartasMazo; index++) {
            let carta = document.getElementById("mazo" + index);
            carta.style.zIndex = "0"
            setTimeout(() => {
              carta.style.transition = ""
            }, 200);
          }
        }, 3000);
      }
    }, 10);
  }
  ngOnInit(): void {
    this.idPartida = this.activated.snapshot.params.id;
    localStorage.setItem('sala', this.idPartida)
    let orde = localStorage.getItem("orde");
    if (orde)
      this.eu.orde = parseInt(orde)
    this.eu.hash = localStorage.getItem("userID")
    this.eu.nome = localStorage.getItem("nome")
    // if (this.eu.orde < 0)
    //   this.router.navigate(['/home'])

    this.subscribePartida()
    if (this.eu.orde < 0)
      this.newPlayer()
    else
      this.updateSocket()
  }



  iniciaXogo() {
    this.webService.emit("iniciaXogo", { partida: this.idPartida });
  }

  robaCarta() {
    this.webService.emit("robaCarta", { partida: this.idPartida, kitten: this.eu.orde })
  }

  subscribePartida() {
    this.subscription = this.webService.listen("infoXogo" + this.idPartida).subscribe((data: any) => {
      // switch (data.type) {
      console.log(data)


      if (typeof data.mazo == "number")
        this.cartasMazo = data.mazo;


      if (data.animation == "shuffle") //TODO: si engado máis... if (data.animation) e logo switch
      {
        this.mezcla();
      }

      if (data.future) {
        console.log("cartas futuras!! " + data.future)
        this.cartasFuturas = Array(data.future.length).fill("back.jpg");

        setTimeout(() => {
          for (let index = 0; index < data.future.length; index++) {
            let carta = document.getElementById("futuro" + index);
            carta.style.transform = 'scaleX(-1)'
          }
          setTimeout(() => {
            data.future.forEach((c, index) => {

              let carta = document.getElementById("futuro" + index);
              carta.setAttribute('src', "assets/images/" + c.imx)

              carta.classList.add("cardFlip")
              carta.style.transform = 'scaleX(1)'
              setTimeout(() => {
                carta.style.transform = 'scaleX(-1)'
                setTimeout(() => {
                  this.cartasFuturas = [];
                }, 400);

              }, 10000);
            });
          }, 200);
        }, 2000);
      }


      if (data.eu) {
        localStorage.setItem("userID", data.eu.hash)
        localStorage.setItem("orde", data.eu.orde)
        this.eu = data.eu;
        this.rivals = data.rivals;
        this.players = [...data.rivals]
        this.players.splice(this.eu.orde, 0, this.eu);
        this.normas = data.normas;
        //TODO: QUE DEVOLVA A CARTA A ELIMINAR, SI NON EXISTE É QUE XA A ELIMINOU
        if (this.eu.ocultaExploding) {
          this.openModal({ type: "escondeExploding", cartasMazo: Array(this.cartasMazo).fill({ imx: "back.jpg" }).concat(this.eu.cartas.find(c => c.type == "exploding")) });

        }
      }

      if (data.xogada) {
        console.log("xogada: ", data.xogada)
        this.xogadaActual = data.xogada;

        if (data.xogada.ataque) {
          this.xogandoAtaque(data.xogada)
        }
        else
          this.deselectCards();
      }



      if (data.sumaCarta) {
        console.log(data.sumaCarta)
        let imaxe = data.sumaCarta.imx;
        data.sumaCarta.imx = 'back.jpg'
        this.eu.cartas.push(data.sumaCarta);
        setTimeout(() => {
          let carta = document.getElementById(data.sumaCarta.id);
          carta.classList.add("cardFlip")
          carta.style.transform = 'scaleX(-1)'
          setTimeout(() => {
            carta.style.transform = 'scaleX(1)'
            data.sumaCarta.imx = imaxe;
          }, 200);
        }, 300);
      }


      if (data.defuse) {
        this.eu.ocultaExploding = true;
        setTimeout(() => {
          this.quitaCarta(data.defuse.id);
        }, 300);

      }


      if (typeof data.restaCarta == "number") {
        let restaCarta = data.restaCarta;
        this.quitaCarta(restaCarta);
      }



      if (data.explota) {

        if (data.explota.sinDefuse) {
          setTimeout(() => {

            this.rivals = this.rivals.map(r => {
              if (r.orde > data.explota.orde)
                r.orde--;
              else if (r.orde == data.explota.orde)
                r.orde = null;
              return r;
            }).filter(r => r.orde != null);


            if (data.explota.orde == this.eu.orde) {
              this.showNotification("🧨✨ Adeus gatiño!!", "Non te quedaban defuses, perdiches 😰", "explota")
              this.eu.cartas = [];
              this.eu.orde = null;
            }
            else {
              this.showNotification("🧨✨ Explotou " + data.explota.nome, "Bendita sea su memoria, que su alma encuentre resguardo entre los vivos", "explota")

              if (this.eu.orde > data.explota.orde) {
                this.eu.orde--;
                //TODO: ACTUALIZAMOS ASÍ??
                localStorage.setItem("orde", this.eu.orde.toString())
              }
              console.log(this.rivals)

            }
            if (data.winner) {
              if (data.winner.hash == this.eu.hash)
                setTimeout(() => {
                  this.openModal({ type: "targetWinner" })
                }, 3000);
              else
                setTimeout(() => {
                  this.openModal({ type: "targetWinner", winner: data.winner.nome })
                }, 3000);
            }

          }, 2000);

        }
        else {
          //EXPLOTA CON DEFUSE 
          if (data.explota.orde != this.eu.orde) {
            this.showNotification("🧨Exploding card!! ", data.explota.nome + " xoga defuse, e esconde carta no mazo", "casiexplota")
          }
          else {
            this.showNotification("🧨Exploding card!! ", " ...pero tranqui, que desta te salvaches!", "casiexplota", 3)

          }
        }
      }

      if (data.error) {
        if (data.error == 'Incorrect ID') {
          localStorage.removeItem("orde")
          this.eu.orde = undefined;
          this.newPlayer();
        }
        else
          alert(data.error)
      }



      if (data.descartes) {

        this.cartasDescartes = data.descartes;

        //TODO: Esto non estaba funcionando moi ben -->
        if ((!(typeof data.turno == "number") || data.updateCats) && this.turno != this.eu.orde)
          setTimeout(() => {
            console.log("descartes" + (data.descartes.length - 1))
            let ultDes = document.getElementById("descartes" + (data.descartes.length - 1))
            console.log(ultDes)
            if (ultDes)
              ultDes.classList.add("zoomInOut");
          }, 40);
      }


      if (data.newCat) {
        if (data.newCat.orde != this.eu.orde) {
          this.rivals.push(data.newCat);
          this.players.push(data.newCat);
        }
      }

      if (data.updateCats) {
        console.log("updatecats!!")
        data.updateCats.forEach(updateCat => {
          if (updateCat.orde != this.eu.orde) {
            this.rivals.find(r => r.orde == updateCat.orde).cartas = updateCat.cartas
          }
        });
      }

      if (typeof data.turno == "number") {
        if (this.turno != data.turno && data.turno == this.eu.orde && !this.ataqueEnCurso) {
          document.getElementById("mesa-tab").click();
          this.showNotification("Xogas tí!", "", "success", 1)
        }
        this.turno = data.turno;
        if (this.turno > -1 && this.eu.orde != this.turno)
          this.rivalXogando = this.rivals.find(r => r.orde == this.turno).nome
      }

      if (data.notification
        && (!data.notification.ignore || !data.notification.ignore.includes(this.eu.orde))) {
        console.log(data.notification)
        this.showNotification(data.notification.texto, data.notification.info, data.notification.tipo, data.notification.dismiss, data.notification.espera)
      }

    })

  }

  private quitaCarta(restaCarta: any) {
    if (this.modalData) {
      this.modalData.cartaPerdida = restaCarta;
    }
    else {
      let carta = document.getElementById(restaCarta);
      carta.classList.add("cartaPerdida");
    }
    setTimeout(() => {
      this.eu.cartas = this.eu.cartas.filter(c => c.id != restaCarta);
      if (this.modalData) {
        document.getElementById("closeModalButton").click();
        this.modalData = null;
      }
      else if (this.eu.ocultaExploding) {
        this.openModal({ type: "escondeExploding", cartasMazo: Array(this.cartasMazo).fill({ imx: "back.jpg" }).concat(this.eu.cartas.find(c => c.type == "exploding")) });
      }
    }, 4000);
  }

  private updateProgressbar() {
    setTimeout(() => {
      console.log(this.notificationData["progreso"] + " de " + this.notificationData["espera"])
      this.notificationData["progreso"]++;
      if (this.notificationData["progreso"] < this.notificationData["espera"])
        this.updateProgressbar();
    }, 1000);
  }

  private showNotification(texto: string, info: string, tipo: string, dismiss = 0, espera = 0) {
    console.log("notification!", texto, info, tipo, dismiss, espera)
    console.log(this.xogadaActual)
    // podemos poñer o png e a cor de fondo en tipo, e simplemente comproba o typeof, si non
    // é string temos algo de ese estilo  
    this.notificationData = { show: true, texto, info, tipo: tipo, espera, progreso: 0 };
    console.log(this.xogadaActual)
    if (typeof tipo != "string") {
      this.notificationData["gif"] = tipo["gif"];
      if (tipo["bg"])
        this.notificationData["tipo"] = tipo["bg"]
    }
    else if (this.xogadaActual["tipo"]) {
      this.notificationData["gif"] = this.xogadaActual["tipo"] + ".png"
      console.log("*****************" + this.notificationData["gif"])
    }
    else if (tipo.includes("png")) {
      this.notificationData["gif"] = tipo
      this.notificationData["tipo"] = "info"
    }
    else if (tipo == "explota") {
      this.notificationData["gif"] = "exploded.gif"
      this.notificationData["tipo"] = "danger"
    }
    else if (tipo == "casiexplota") {
      this.notificationData["gif"] = "getExploding.png"
      this.notificationData["tipo"] = "warning"
    }

    if (this.xogadaActual && this.xogadaActual["victima"] == this.eu.orde)
      this.notificationData["tipo"] = "danger"
    console.log(this.notificationData);
    dismiss = espera || dismiss;
    if (this.timeout)
      clearTimeout(this.timeout)
    console.log("dismiss ", dismiss, " espera ", espera)
    if (dismiss > 0)
      this.timeout = setTimeout(() => {
        this.notificationData["show"] = false;
        console.log("oculto notification!");
      }, dismiss * 1000);

    if (espera > 0) {
      console.log("lanzo progressbar, espera = " + espera + "tipo: " + typeof espera)
      this.updateProgressbar();
    }
  }


  esperando() {
    return (this.rivals.length == 0 || this.rivals.length + 1 < this.normas["minXogadores"])
  }

  newPlayer() {
    console.log("new player")
    this.webService.emit("hellowKitten", { partida: this.idPartida, kitten: this.eu.orde, nome: this.eu.nome });
  }

  updateSocket() {
    console.log("updatesocket")
    this.webService.emit("hellowKitten", { partida: this.idPartida, kitten: this.eu.orde, hash: this.eu.hash, nome: this.eu.nome });
  }


  // ----------------- ACTUALIZANDO ---------------
  deselectCards() {
    this.eu.cartas.forEach(c => c.selected = false)
    this.xogadaMultiple = [];
    console.log(this.xogadaMultiple)
  }
  clickCard(c) {
    if (!this.modalData) {
      c.selected = !c.selected;
      this.xogadaMultiple = this.eu.cartas.filter(c => c.selected == true)
      console.log(this.xogadaMultiple)
    }
  }

  dragEnd(event: CdkDragEnd<string[]>) {
    //console.log(event.source.element.nativeElement.id)
    let y = event.source.element.nativeElement.getBoundingClientRect().y
    let x = event.source.element.nativeElement.getBoundingClientRect().x
    let carta = this.eu.cartas.find(c => c.id == event.source.element.nativeElement.id);
    if (carta) {
      // carta.pos = { x, y }
      // console.log(this.eu)
      event.source.element.nativeElement.style.zIndex = (1000 + x).toString();
      //event.source.element.nativeElement.style.zIndex = (1000 + parseInt(event.source.element.nativeElement.id) + event.source.getFreeDragPosition().x).toString()

      //  console.log(y, document.getElementById("tabs").getBoundingClientRect().y);
      //  console.log(y - document.getElementById("tabs").getBoundingClientRect().y)
      if (y < document.getElementById("tabs").getBoundingClientRect().y) {
        carta.selected = true;

        //TODO: CAMBIAR POR ZONA BLOQUEADA NO HTML? 
        this.xogadaMultiple = this.eu.cartas.filter(c => c.selected == true)
        console.log(this.xogadaMultiple)

        if (!this.podoXogar(carta)) {
          //TODO: podemos almacenar posición da carta (transform) e recuperalo

          console.log("reset")
          event.source._dragRef.reset();
          setTimeout(() => {
            this.deselectCards()
          }, 200);
        }
        else {
          this.iniciaXogada()
        }


      }
      else {
        setTimeout(() => {
          carta.selected = false;
          this.xogadaMultiple = this.eu.cartas.filter(c => c.selected == true)
          console.log(this.xogadaMultiple)
        }, 200);

      }

    }
  }

  //TODO: Crear clase para this.eu e neste caso chamaríamos función this.eu.descarta()

  iniciaXogada() {
    console.log(this.xogadaMultiple)
    console.log(this.xogadaMultiple.length)
    if (
      (
        (this.xogadaMultiple.length > 1 && this.xogadaMultiple.length != 5) ||
        (this.xogadaMultiple.length == 1 && this.xogadaMultiple[0].type == "favor"))
      &&
      typeof (this.victima) != "number"
    ) {

      if (this.rivals.length == 1) {
        this.victima = this.rivals[0].orde;
        this.iniciaXogada();
      }
      else
        this.openModal({ type: "selectVictima", rivals: this.rivals });

    }
    else {
      if (this.xogadaMultiple.length > 2) {
        this.openModal({ type: "targetPideCarta" });
        //  TEST this.cartaEnXogo = carta;
      }
      else {
        console.log("carta en mesa")
        console.log(this.victima)
        this.cartasEnMesa(this.xogadaMultiple, this.victima)
      }
    }
  }


  descarta(cartas: any[]) {
    // o primeiro xa vale pra todos, aínda que é menos comprensible
    if (cartas.length > 1)
      this.eu.cartas = this.eu.cartas.filter(c => !cartas.map(x => x.id).includes(c.id))
    else
      this.eu.cartas = this.eu.cartas.filter(c => c.id != cartas[0].id)

  }

  continuaXogada(data: any) {
    switch (this.xogadaActual["tipo"]) {
      case "favor":
        let carta = data;
        this.xogadaActual = {}
        this.deselectCards();
        this.modalData = null;
        this.webService.emit("continuaXogada", { partida: this.idPartida, kitten: this.eu.orde, carta })
        break;

      case "rouba":
        if (this.xogadaActual["etapa"] == 1) {
          this.xogadaActual = {}
          this.modalData = null;
        }
        this.webService.emit("continuaXogada", { info: data, partida: this.idPartida });
        break;

      default:
        break;
    }
  }

  cartasEnMesa(cartas: any[], victima = undefined, carta = undefined) {
    console.log((new (Date)).getMinutes())
    console.log("carta en mesa", cartas, victima, carta)
    console.log("play!")

    this.descarta(cartas)

    if (this.xogadaActual["ataque"]) {
      //ir modificando, de momento só temos favor
      let carta = cartas[0];
      this.continuaXogada(carta)
    }
    else {
      console.log("xogada en curso!")
      this.xogadaActual = { espera: 10 }
      this.webService.emit("iniciandoXogada", { partida: this.idPartida, kitten: this.eu.orde, cartas, victima, carta })
      this.victima = null;
    }

  }

  // ------------- ACTUALIZADO ------------------------

  private xogandoAtaque(xogada: any) {

    let atacante = xogada.quen;
    let victima = this.rivals.find(r => r.orde == xogada.victima)
    let rol = "";
    if (!victima)
      rol = "victima"
    else if (atacante.orde == this.eu.orde)
      rol = "atacante"

    if (rol == "")
      this.showNotification(atacante.nome + " xogando " + xogada.tipo + " contra " + victima.nome,
        "", "info")
    else
      switch (xogada.tipo) {
        case "rouba":
          if (rol == "atacante") {
            if (!this.modalData)
              this.openModal({ type: "targetRouba", victima })
            if (xogada.etapa == 1) //TODO: xuntar co de abaixo e pasar a un
              this.modalData.cartasMezcladas = true;
          }
          else if (rol == "victima") {

            if (!this.modalData)
              this.openModal({ type: "targetMezclaCartas", cartaPerdida: -1, atacante, cartas: this.eu.cartas })
            if (xogada.etapa > 0)
              this.modalData.cartasMezcladas = true;
          }
          break;

        case "favor":
          if (rol == "victima") {
            this.openModal({ type: "targetFavor", atacante, cartas: this.eu.cartas })
          }
          else
            this.showNotification(victima.nome + " está escollendo a carta que entregar", "Nunca é fácil deprenderse dun gatiño....", "info")

        default:
          break;
      }
  }
}



