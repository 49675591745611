<!-- Button trigger modal -->
<button id="openModalButton" [hidden]="true" data-backdrop="static" data-toggle="modal" data-target="#exampleModal">Open
    Modal</button>


<!-- Modal -->

<div class="modal fade" *ngIf="data" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel">
    <div class="modal-dialog" [ngClass]="{'modal-lg': data.type!='selectVictima'}" role="document">
        <div class="modal-content bg-light">
            <div class="modal-header bg-info">
                <h1 class="modal-title text-light  text-center w-100" id="exampleModalLabel">
                    <span *ngIf="data.type=='targetWinner'">
                        {{data.winner ? data.winner + " gañou esta partida!" : "Gañaches esta partida!!!"}}
                    </span>
                    <span *ngIf="data.type=='targetPideCarta'">
                        Selecciona o tipo de carta que queres roubar.
                    </span>
                    <span *ngIf="data.type=='selectVictima'">
                        Selecciona víctima.
                    </span>
                    <span *ngIf="data.type=='escondeExploding'">
                        Oculta exploding card.
                    </span>
                    <span *ngIf="data.type=='targetFavor'">
                        Selecciona carta 
                    </span>
                    <span *ngIf="data.type=='targetMezclaCartas'">
                        <span *ngIf="!data.cartasMezcladas">Mezcla as cartas antes de que che rouben unha!!!.</span>
                        <span *ngIf="data.cartasMezcladas">Cruza os dedos...</span>
                    </span>
                    <span *ngIf="data.type=='targetRouba'">
                        Roubando cartas...
                    </span>
                </h1>
                <button id="closeModalButton" type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div *ngIf="data.type=='targetWinner'">
                    <img *ngIf="data.winner" src="assets/images/dealwithit.gif" width="100%">
                    <img *ngIf="!data.winner" src="assets/images/win.gif" width="100%">
                    <button class="w-100 text-center btn  btn-outline-success btn-lg" data-dismiss="modal"
                        onclick="window.location.reload();">Xogar outra partida</button>
                </div>
                <div *ngIf="data.type=='selectVictima'">
                    <div class="list-group">
                        <button *ngFor="let rival of data.rivals" (click)="selectVictima(rival.orde)"
                            data-dismiss="modal" class="list-group-item list-group-item-action"> {{rival.nome}}
                        </button>
                    </div>
                </div>
                <div *ngIf="data.type=='escondeExploding'" class="bg-white">

                    <div class="row align-items-center">
                        <div class="col-10 text-center text-info">
                            <div cdkDropList cdkDropListOrientation="horizontal" class="card-list"
                                (cdkDropListDropped)="dropExploding($event)">
                                <div class="card-box" *ngFor="let c of data.cartasMazo;let i=index" cdkDrag
                                    [cdkDragDisabled]="c.imx=='back.jpg'">
                                    <img [ngStyle]="{'width': getCardsWidth(data.cartasMazo)}"
                                        [src]="'assets/images/' + c.imx">
                                </div>
                            </div>
                        </div>
                        <div clas="col-1">
                            <img src="assets/images/defuse.gif" width="100px">
                        </div>
                    </div>
                    <div class="row">
                        <h1 width="50%" class="rounded mx-auto d-block text-info">{{(posicionExploding < 0 ? 1:
                                data.cartasMazo.length - posicionExploding)}}</h1>
                                <button class="w-100 text-center btn btn-outline-success btn-sm" data-dismiss="modal"
                                    (click)="posExploding(posicionExploding)">Feito!</button>
                    </div>
                </div>
                <div *ngIf="data.type=='targetFavor'">
                    <h1 class="text-center">{{data.atacante.nome}} pediuche unha carta, cómo dicirlle que non? </h1>
                    <img width="50%" class="rounded mx-auto d-block" src="assets/images/favor.gif">
                    <div class="card-list mt-3">
                        <div class="card-box favor" *ngFor="let c of data.cartas;let i=index">
                            <a href="#" data-dismiss="modal"><img
                                    [ngClass]="{'cartaPerdida': data.cartaPerdida == c.id}" (click)="entregaCarta(c)"
                                    [ngStyle]="{'cursor': 'pointer', 'width': getCardsWidth(data.cartas)}"
                                    [src]="'assets/images/' + c.imx"></a>
                        </div>
                    </div>
                </div>
                <div *ngIf="data.type=='targetRouba'">
                    <div *ngIf="data.cartasMezcladas" class="card-list">
                        <div class="card-box rouba-carta-box"
                            *ngFor="let e of [].constructor(data.victima.cartas);let i=index">
                            <a href="#" data-dismiss="modal"><img class="rouba-carta" (click)="colleCarta(i)"
                                    [ngStyle]="{'cursor': 'pointer', 'width': getCardsWidth(data.victima.cartas)}"
                                    src="assets/images/back.jpg"></a>
                        </div>
                    </div>
                    <div *ngIf="!data.cartasMezcladas" class="text-center"><img src="assets/images/shuffle.gif"></div>
                    <div class="modal-footer mt-3 text-muted">
                        <div class="d-flex align-items-center">
                            <strong *ngIf="!data.cartasMezcladas"> {{data.victima.nome}} está mezclando as súas
                                cartas...</strong>
                            <strong *ngIf="data.cartasMezcladas"> Selecciona a carta que queres roubarlle a
                                {{data.victima.nome}}</strong>

                        </div>
                    </div>
                </div>
                <div *ngIf="data.type=='targetMezclaCartas'">
                    <div cdkDropList cdkDropListOrientation="horizontal" class="card-list"
                        (cdkDropListDropped)="drop($event)">
                        <div class="card-box" *ngFor="let c of data.cartas" cdkDrag
                            [cdkDragDisabled]="data.cartasMezcladas">
                            <img [ngClass]="{'cartaPerdida': data.cartaPerdida == c.id}"
                                [ngStyle]="{'width': getCardsWidth(data.cartas)}" [src]="'assets/images/' + c.imx">
                        </div>
                    </div>
                    <button *ngIf="!data.cartasMezcladas" type="button" class="mt-3 w-100 btn btn-outline-success"
                        (click)="cartasMezcladas()">Feito!</button>
                    <div class="modal-footer row" *ngIf="data.cartasMezcladas">
                        <img width="50%" class="rounded mx-auto d-block" src="assets/images/roubando.gif">
                        <div class="w-100 text-center text-info"> {{data.atacante.nome}} roubando carta..</div><br>

                    </div>
                </div>
                <div *ngIf="data.type=='targetPideCarta'">
                    <div class="row">
                        <a data-dismiss="modal" class="col-3">
                            <img src="assets/images/kitten5.png" (click)="pideCarta('kitten5')"
                                class="img-fluid select-card">

                        </a>
                        <a data-dismiss="modal" class="col-3">
                            <img src="assets/images/kitten4.png" (click)="pideCarta('kitten4')"
                                class="img-fluid select-card">
                        </a>
                        <a data-dismiss="modal" class="col-3">
                            <img src="assets/images/kitten3.png" (click)="pideCarta('kitten3')"
                                class="img-fluid select-card">
                        </a>
                        <a data-dismiss="modal" class="col-3">
                            <img src="assets/images/kitten2.png" (click)="pideCarta('kitten2')"
                                class="img-fluid select-card">
                        </a>
                    </div>
                    <div *ngIf="data.type=='targetPideCarta'">
                        <div class="row">
                            <a data-dismiss="modal" class="col-3">
                                <img src="assets/images/kitten1.png" (click)="pideCarta('kitten1')"
                                    class="img-fluid select-card">

                            </a>
                            <a data-dismiss="modal" class="col-3">
                                <img src="assets/images/defuse.png" (click)="pideCarta('defuse')"
                                    class="img-fluid select-card">
                            </a>
                            <a data-dismiss="modal" class="col-3">
                                <img src="assets/images/shuffle.png" (click)="pideCarta('shuffle')"
                                    class="img-fluid select-card">
                            </a>
                            <a data-dismiss="modal" class="col-3">
                                <img src="assets/images/skip.png" (click)="pideCarta('skip')"
                                    class="img-fluid select-card">
                            </a>
                        </div>
                        <div *ngIf="data.type=='targetPideCarta'">
                            <div class="row">
                                <a data-dismiss="modal" class="col-3">
                                    <img src="assets/images/future.png" (click)="pideCarta('future')"
                                        class="img-fluid select-card">

                                </a>
                                <a data-dismiss="modal" class="col-3">
                                    <img src="assets/images/nope.png" (click)="pideCarta('nope')"
                                        class="img-fluid select-card">
                                </a>
                                <a data-dismiss="modal" class="col-3">
                                    <img src="assets/images/favor.png" (click)="pideCarta('favor')"
                                        class="img-fluid select-card">
                                </a>
                                <a data-dismiss="modal" class="col-3">
                                    <img src="assets/images/attack.png" (click)="pideCarta('attack')"
                                        class="img-fluid select-card">
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer mt-3 text-muted">
                        ...vas roubar algo que non sexa un Defuse? 🤔
                        <!--button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary">Save changes</button-->
                    </div>
                </div>
            </div>
        </div>