import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { WebSocketService } from '../services/web-socket.service'
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor(private webService: WebSocketService, private router:Router) { }

  kitten= "";
  sala= "";

  ngOnInit(): void {
    this.sala = localStorage.getItem('sala');
    this.kitten = localStorage.getItem('orde');
  //  this.webService.emit("Ola", { mensaxe: "testeando sockets..." });
  //  this.webService.listen("test").subscribe((data: any) => {
  //    console.log("Recibido en home ", data);
  //  })
  }
  xogar() {
    //TODO: CHECKEAR SI PARTIDA INICIADA E SI EXISTE XA ESE NOME DE XOGADOR.
    localStorage.setItem('nome', this.kitten);
    this.router.navigate(['/partida', this.sala])
    console.log(this.kitten, this.sala)
  }

}
