<div>
    <img src="/assets/head.jpg" class="w-100">
</div>
<div class="sticky-top" style="z-index: 6000">
    <nav class="navbar navbar-light bg-light">
        <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item">
                <a class="nav-link active lead" id="mesa-tab" data-toggle="tab" href="#tab-mesa" role="tab"
                    aria-controls="mesa" aria-selected="true" (click)="victima=null">Mesa</a>

            </li>
            <li *ngFor="let player of players" class="nav-item">
                
                <a *ngIf="player.orde!=null && player.orde==eu.orde" class="nav-link"  disabled  role="tab" [ngClass]="{'text-success': turno == player.orde}"
                    aria-disabled="true" aria-selected="false" >{{player.nome}}</a>

                <a *ngIf="player.orde!=null && player.orde!=eu.orde" class="nav-link"  [id]="player.orde + '-tab'" [ngClass]="{'text-success': turno == player.orde}" data-toggle="tab" [href]="'#tab' + player.orde" role="tab"
                    aria-controls="profile" aria-selected="false" (click)="victima = player.orde">{{player.nome}}</a>

                </li>
        </ul>
        <h2 *ngIf="turno>-1" class="text-info">{{eu.orde==turno? "Xogas tí" : "Xoga " + rivalXogando}}
        </h2>
        <div *ngIf="eu.orde==0">
            <button (click)="iniciaXogo()" *ngIf="turno == -1" [disabled]="esperando()" class="btn"
                [ngClass]="esperando()? 'btn-secondary' : 'btn-success'">{{esperando()? "Esperando..." :
                "Vamos!"}}</button>
        </div>
        <div *ngIf="turno>=0">
            <button type="button" class="btn btn-info btn-sm position-relative mr-3 px-2" (click)="deselectCards()" *ngIf="xogadaMultiple.length">
                {{xogadaMultiple.length}} {{xogadaMultiple.length>1?"cartas":"carta"}}
                <span class="badge rounded-pill bg-light text-info ml-2">
                    ×
                </span>
            </button>
                 
            <button class="btn btn-outline-danger mr-1" id="openModalButton" [hidden]="!modalData" data-toggle="modal"
                data-target="#exampleModal">⚠</button>
            <button class="btn btn-outline-success " (mouseup)="topMazo = 0;rightMazo = -200;" (touchstart)="topMazo = 0;rightMazo = -200;"
               (touchend)="rightMazo=-180;" (mousedown)="rightMazo=-180;">👀</button>


            <div *ngFor="let accion of normas.acciones" class="form-inline">
                <div *ngFor="let e of accion.form">
                    <select class="custom-select" *ngIf="e.type == 'select'" [(ngModel)]="accion.values[e.nome]">
                        <option *ngFor="let option of e.options; let i = index">{{option}}</option>
                    </select>
                    <input class="form-control" type="text" [title]="e.tooltip" *ngIf="e.type == 'input'"
                        [(ngModel)]="accion.values[e.nome]" [placeholder]="e.placeholder">
                    <button *ngIf="e.type == 'button'" class="btn btn-primary"
                        (click)="this[e.accion]()">{{e.texto}}</button>
                </div>
            </div>
        </div>
    </nav>
</div>
<div class="tab-content bg-warning" id="tabs">
    <div class="mb-10 tab-pane fade show active" id="tab-mesa" role="tabpanel" aria-labelledby="tab-mesa">
        <div *ngIf="turno>=0" class="row w-100">
            <div class="col-9">
                <img  [cdkDragDisabled]="xogadaActual.espera>0 || xogadaActual.ataque || turno!=eu.orde || eu.ocultaExploding " class="cards-box"
                    *ngFor="let e of [].constructor(cartasMazo);let i=index" [id]="'mazo' + i" cdkDrag
                    (cdkDragEnded)="dragMazoEnd($event)" src="assets/images/back.jpg" 
                    [style]="{'margin-right': rightMazo + 'px', 'margin-top': (i*topMazo + 'px') }" >
                <img (click)="cartasFuturas = []" *ngFor="let futuro of cartasFuturas;let i=index" style="margin-right: -200px; margin-top:0px"
                    [src]="'assets/images/' + futuro" class="cards-box futuro" [id]="'futuro' + i">
            </div>
            <div style="margin-right:200px" class="col-1">
                <img class="cards-box" [id]="'descartes' + i" *ngFor="let c of cartasDescartes;let i=index"
                    [src]="'assets/images/' + c.imx" style="margin-right: -200px; margin-top: 0px">
            </div>
        </div>
    </div>

    <div *ngFor="let rival of rivals" class="tab-pane fade" [id]="'tab' + rival.orde" role="tabpanel"
        aria-labelledby="profile-tab" style="min-height: 250px;">
        <img class="cards-box" *ngFor="let e of [].constructor(rival.cartas);let i=index" src="assets/images/back.jpg"
            [style]="{'margin-right': rightMazo + 'px', 'margin-top': (i*topMazo + 'px') }">

    </div>
</div>
<app-notification [dataFromPartida]="notificationData"></app-notification>
<div *ngIf="this.eu.orde == null && this.turno>=0 && this.rivals.length>1">

    <img src="assets/images/espera.gif" width="100%">
</div>

<div id="cartasXogador">
    <img *ngFor="let c of eu.cartas; let i=index" class="cards-box" [src]="'assets/images/' + c.imx" [id]="c.id"
        style="margin-right: -200px;" [style]="{'z-index': 1000 + i*100}" [ngClass]="{'selected': c.selected}" cdkDrag (click)="clickCard(c)" (mouseup)="mouseup($event)"
        (mousedown)="mousedown($event)" [cdkDragFreeDragPosition]="dragPosition" (cdkDragEnded)="dragEnd($event)">
</div>
<div><img width="100%" src="assets/images/instruccions.jpg"></div>
<!--  
<div id="cartasXogador">
    <img *ngFor="let c of eu.cartas; let i=index" class="cards-box" [src]="'assets/images/' + c.imx" [id]="c.id"
        style="margin-right: -200px; transform: scaleX(-1)" [style]="{'z-index': 1000 + i*100}" cdkDrag
        (mouseup)="mouseup($event)" (mousedown)="mousedown($event)" [cdkDragFreeDragPosition]="dragPosition"
        (cdkDragEnded)="dragEnd($event)">
</div>-->
<app-modal [dataFromPartida]="modalData" (modalRes)="modalRes($event)"></app-modal>