<div class="body">
    <div id="login">
        <div class="container">
            <div id="login-row" class="row justify-content-center align-items-center">
                <div id="login-column" class="col-md-6">
                    <div id="login-box" class="col-md-12">
                        <div id="login-form" class="form">
                            <h3 class="text-center text-info">Entrar/crear sala</h3>
                            <div class="form-group">
                                <label for="username" class="text-info">Nome:</label><br>
                                <input type="text" class="form-control" [(ngModel)]="kitten">
                            </div>
                            <div class="form-group">
                                <label for="password" class="text-info">Sala:</label><br>
                                <input type="text" class="form-control" [(ngModel)]="sala">
                            </div>
                            <div class="form-group">
                                <input type="submit" name="submit" class="btn btn-info btn-md w-100" value="Entrar" (click)="xogar()">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>